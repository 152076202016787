import React from "react";
import Layout from "../components/layout";
import CtaBanner from "../components/cta-banner";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Button } from "react-bootstrap";
import parse from "html-react-parser";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";

const BlogPostTemplate = ({ data }) => {
	const post = data?.wpPost;

	const siteUrl = data.site.siteMetadata;

	return (
		<Layout>
			<GatsbySeo
				title={post.title}
				description={post.blogSeo.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/${post.slug}`,
					title: `${post.title}`,
					description: `${post.blogSeo.metaDescription}`,
					images: [
						{
							url: `${siteUrl}/${post.slug}`,
							width: 1920,
							height: 1080,
							alt: `${post.title}`,
						},
					],
				}}
			/>
			<Container>
				<Row className="py-5">
					<Col>
						<h1 className="text-primary text-center">{post.title}</h1>
					</Col>
				</Row>
				<Row>
					<Col>
						{!!post.content && (
							<section className="page-text blog-content">
								{parse(post.content)}
							</section>
						)}
					</Col>
				</Row>
				<Row className="pt-4">
					<Col>
						<h4>
							Are you feeling ambitious but stuck without a plan to move
							forward? Please visit one of the links below to find out more:
						</h4>
					</Col>
				</Row>
			</Container>
			<section className="py-6">
				<Container>
					{/* <Row>
            <Col>
              <h2>My Coaching and Mentoring Services</h2>
                          </Col>
          </Row> */}
					<Row>
						{/* <Col className="text-center mb-4" xs={12} md={4}>
              <StaticImage
                src="../images/jason-client-meeting-3.jpg"
                style={serviceimg}
                quality={100}
              />
              <h3 className="py-3 cta-btn-gtm">Business Coaching</h3>
              <Button variant="outline-secondary" as={Link} to="/">
                Find Out More
              </Button>
            </Col> */}

						<Col className="text-center mb-5" xs={12} md={6} lg={4}>
							<StaticImage
								className="blog-service-img"
								src="../images/1.jpg"
								quality={100}
							/>
							{/* <h5 className="py-3 smaller-heading">
                  Small Business Owner? Read more about Business Coaching here:
                </h5> */}
							<Button
								className="cta-btn-gtm mt-3"
								variant="outline-secondary"
								as={Link}
								to="/packages"
							>
								Packages and Pricing
							</Button>
						</Col>
						<Col className="text-center mb-5" xs={12} md={6} lg={4}>
							<StaticImage
								className="blog-service-img"
								src="../images/14.jpg"
								quality={100}
							/>
							{/* <h5 className="py-3 smaller-heading">
                  Read more about Executive Coaching here:
                </h5> */}
							<Button
								className="cta-btn-gtm mt-3"
								variant="outline-secondary"
								as={Link}
								to="/about"
							>
								About Jason
							</Button>
						</Col>
						{/* <Col className="text-center mb-5  " xs={12} md={6} lg={3}>
							<StaticImage
								className="blog-service-img"
								src="../images/life-coaching-hero.jpg"
								quality={100}
							/>

							<Button
								className="cta-btn-gtm mt-3"
								variant="outline-secondary"
								as={Link}
								to="/life-coaching"
							>
								Life Coaching
							</Button>
						</Col> */}
						<Col className="text-center mb-5 " xs={12} md={6} lg={4}>
							<StaticImage
								src="../images/2.jpg"
								className="blog-service-img"
								quality={100}
							/>
							<Button
								className="cta-btn-gtm mt-3"
								variant="outline-secondary"
								as={Link}
								to="/frequently-asked-questions"
							>
								Frequently Asked Questions
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<CtaBanner
				headline="Ready to Book a discovery meeting?"
				btnTxt="Get in Touch"
				btnLink="/contact-us"
				bgColor="light"
			/>
		</Layout>
	);
};

export default BlogPostTemplate;

export const blogData = graphql`
	query ($id: String!) {
		wpPost(id: { eq: $id }) {
			id
			slug
			title
			excerpt
			content
			blogSeo {
				metaDescription
			}
		}

		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
